import React from 'react';

import i18n from 'i18next';

import './styles.scss';

export class CostumInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            label: false,
            value: ''
        }
    }

    handleFocus = () => {
        this.setState({
            label: true
        })

        if(this.props.handleFocus) this.props.handleFocus()
    }

    handleBlur = () => {
        this.setState({
            label: false
        })

        if(this.props.handleBlur) this.props.handleBlur()
    }


    handleChange = (event) => {
        const value = event.target.value;
        this.setState({
            value: value
        })
        if(this.props.handleChange) this.props.handleChange(event);
    };

    render() {
        const {
            placeholder, id, handleVisibility, icon, error, type = 'text', controlVisibility
        } = this.props;
        return (
            <div className={`
                input-group
                ${error ? 'input-group-error' : ''}
                ${this.state.label ? `input-group-focused${this.props.style}` : ''}`}>
                <label className={`
                    label
                    ${this.state.value || this.state.label ? 'visible' : 'invisible'}`} >
                            {placeholder}
                </label>
                <div className={`input-row ${this.props.style}`}>
                    {icon && <i className={icon + " icon"} />}
                    <div className={`input-wrapper`}>
                        <input type={type} className="form-input" onChange={this.handleChange} id={id} onFocus={this.handleFocus} onBlur={this.handleBlur} placeholder={this.state.label ? '' : placeholder} />
                        {controlVisibility && <button type="button" className="visibility-button" data-automation-id="visibility-button" onClick={handleVisibility}><i className="icon-ojo visibility-icon"></i></button>}
                    </div>
                </div>
                  {error && <p className="error">{error.includes(i18n.t("error.reset_password_email.backend")) ? i18n.t("error.reset_password_email") : error}</p>}
            </div>
        )
    }

}


CostumInput.propTypes = {};

export default CostumInput;