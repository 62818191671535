import "./FilterAlertsDesktop.scss";
import { useEffect, useRef, useState } from "react";
import { Card } from "@mui/material";
import MultiSelectInput from "components/MainApp/atoms/Forms/MultiSelectInput";
import DateRangePicker from "components/MainApp/atoms/Filters/DateRangePicker/DateRangePicker";
import DevicesActivationButton from "components/MainApp/organisms/DevicesActivationButton/DevicesActivationButton";
import FilterAlertsMobile from "../mobile/FilterAlertsMobile";

const statusOptions = [
  { id: "ALARM", value: "Nueva alerta" },
  { id: "ATTEMPTED_THEFT", value: "Intento de robo" },
  { id: "THEFT", value: "Robo" },
  { id: "FALSE_ALARM", value: "Falsa alarma" }
];

const DEFAULT_FILTER = { id: "ALARM", value: "Nueva alerta" }

const FilterAlertsDesktop = ({ filters, devices }) => {
  const [selectDevices, setSelectDevices] = useState([]);
  const [selectStatus, setSelectStatus] = useState([DEFAULT_FILTER]);
  const [selectDateRange, setSelectDateRange] = useState(null);

  const [isSmallWidth, setIsSmallWidth] = useState(false);
  const cardRef = useRef(null);

  const handleChangeDevices = deviceSelected => {
    setSelectDevices(deviceSelected);
  };

  const handleChangeStatus = statusSelected => {
    setSelectStatus(statusSelected);
  };

  useEffect(() => {
    setSelectDevices(devices);
  }, [devices]);

  useEffect(() => {
    filters.receiveSelection({
      devices: selectDevices.map(device => device.id)
    }, "devices");
  }, [selectDevices]);

  useEffect(() => {
    filters.receiveSelection({
        status: selectStatus.map(status => status.id)
    }, "status");
  }, [selectStatus]);

  useEffect(() => {
    filters.receiveSelection({ dateRange: selectDateRange }, "dateRange");
  }, [selectDateRange]);

  useEffect(() => {
    const debounceResize = (callback, delay) => {
      let timeout;
      return () => {
        clearTimeout(timeout);
        timeout = setTimeout(callback, delay);
      };
    };

    const handleResize = debounceResize(() => {
      if (cardRef.current && cardRef.current.offsetWidth > 0) {
        setIsSmallWidth(cardRef.current.offsetWidth < 1155);
      }
    }, 1000);

    const resizeObserver = new ResizeObserver(handleResize);

    if (cardRef.current) {
      resizeObserver.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        resizeObserver.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <Card ref={cardRef} className="filter-alerts-desktop">
      {isSmallWidth ? (
        <div className="filter-alerts-desktop__small">
          <FilterAlertsMobile
            filters={filters}
            devices={devices}
            isTablet={isSmallWidth}
          />
          <h5>Filtros</h5>
        </div>
      ) : (
        <>
          <MultiSelectInput
            items={devices}
            selected={selectDevices}
            label={"Dispositivos"}
            handleChange={handleChangeDevices}
            classInput="filter-alerts-desktop__input"
            classItems="filter-alerts-desktop__item"
            enableSelectAll
          />
          <MultiSelectInput
            items={statusOptions}
            selected={selectStatus}
            label={"Estados"}
            handleChange={handleChangeStatus}
            classInput="filter-alerts-desktop__input"
            classItems="filter-alerts-desktop__item"
            enableSelectAll
          />
          <DateRangePicker setSelectDateRange={setSelectDateRange} />
        </>
      )}
      <DevicesActivationButton devices={devices} />
    </Card>
  );
};

export default FilterAlertsDesktop;
