import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import "./styles.scss";
import MainButton from "components/MainApp/atoms/RoundedButton";
import { Grid, Modal, useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import Media from "../../Media";
import default_image from '../../../../../assets/images/default_product.png';
import HandSwipeAnimation from "shared/components/animation/hand-swipe-animation/HandSwipeAnimation";
import ProductColoursAndSize from "shared/components/retail/product-color/ProductColoursAndSize";
import { ProductService } from "services/ProductService";
import { StockService } from "services/StockService";
import CostumInput from "components/Auth/atoms/forms/InputCostum";
import { Validations } from "helpers/validations";
import SentEmailPopup from "shared/popups/sent-email-popup/SentEmailPopup";
import { isMobile } from "helpers/Mobile";
import ShareIcon from '@mui/icons-material/Share';

const ProductInfo = ({
  product,
  productRelatedItems,
  imgCover,
  handleClickRecommended,
  historyProducts,
  onHistoryProductClick,
  showCover = false,
  videoImage,
  onClickCover,
  darkMode = false,
  screenState,
}) => {
  // const mainFilesSmall = product?.item_files ?? (product?.image ? [...product.image] : [...default_image]);

  const [mainFilesSmall, setMainFilesSmall] = useState([])
  const [mainFileBig, setMainFileBig] = useState();
  const [open, setOpen] = React.useState(false);
  const [visibleHand, setVisibleHand] = useState(false);
  const [containRetail, setContainRetail] = useState(false);
  const [retailProduct, setRetailProduct] = useState();
  const [stock, setStock] = useState(0);
  const [hasOverflow, setHasOverflow] = useState(false);
  const textRef = useRef(null);
  const containerRef = useRef(null);
  const [mail, setMail] = useState("")
  const [openSentEmailPopup, setOpenSentEmailPopup] = useState();
  const [sellPrice, setSellPrice] = useState()

  useEffect(() => {
    const divElement = document.getElementById('container-image');
    if (divElement) {
      if (divElement.scrollWidth > divElement.clientWidth) {
        setVisibleHand(true);
      }
    }
  }, [historyProducts])


  useEffect(() => {
    if (product) {

      if (containerRef.current && textRef.current) {
        setHasOverflow(textRef.current.scrollHeight > containerRef.current.clientHeight);
      }

      if (product?.item_files.length > 0) {
        setMainFilesSmall(product?.item_files)
      } else {
        setMainFilesSmall(product?.image ? [product.image] : [default_image])
      }
      setMainFileBig(mainFilesSmall?.length > 0 ? mainFilesSmall[0] : null)
      ProductService.product(product.id).then((prod) => {
        setSellPrice(prod.sell_price)
        if (prod.retail) {
          console.log(prod.retail)
          setContainRetail(true);
          setRetailProduct(prod.retail);
        } else {
          setContainRetail(false)
        }

      })

      StockService.localization_product(product.id).then((resp) => {
        setStock(resp.length);
      }).catch(() => {
        setStock(0)
      })

    } else {
      setMainFileBig(null);
      setMainFilesSmall([])
    }


  }, [product]);

  useEffect(() => {
    if (mainFilesSmall && mainFilesSmall.length > 0) {
      setMainFileBig(mainFilesSmall[0]);
    }
  }, [mainFilesSmall]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendEmail = () => {
    console.log("enviar email");
    setOpen(false);
    setOpenSentEmailPopup(true)
  };

  const handleCloseSentEmailPopup = () => {
    setOpenSentEmailPopup(false)
  }

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const setSelectedProduct = (sku) => {
    console.log(sku)
    ProductService.itemBySKU(sku).then((prod) => {
      if (historyProducts.find(historyProd => historyProd.id === prod.id)) {
        historyProducts.push(prod.results[0])
      }
      onHistoryProductClick(prod.results[0])
    })
  }

  const getImageProduct = (product) => {
    let image = product?.item_files[0]?.file_name;
    if (!image) {
      image = product?.image ? product.image : default_image;
    }
    return image;
  }

  const handleChangeEmail = (e) => {
    
    setMail(e.target.value);
    if (!Validations.email(e.target.value) && e.target.value !== ""){

    }
  };

  return (
    <div className={`container-product-info ${darkMode ? "dark" : ""}`}>

      {showCover && videoImage && (
        <div className={`cover ${darkMode ? "dark" : ""}`} onClick={onClickCover}>
          {(darkMode && screenState == "ON") || !darkMode ? (
            <video autoPlay loop>
              <source src={videoImage} type="video/mp4" />
              Tu navegador no soporta la etiqueta video.
            </video>
          ) : null}
        </div>
      )}

      {product &&
        ((darkMode && screenState == "ON") || !darkMode) &&
        <div className={`${darkMode ? "text-white" : ""}`}>
          {darkMode &&
            <div className="detalle-prod">Detalle de Producto</div>
          }
          <Grid container>
            <Grid item xs={isMobile ? 6 : 4}>
              <div className={`column-product-info ${darkMode ? "dark first" : "first"}`}>

                <Media file={mainFileBig} style="image-large" controls={true} />
              
                <div className={`image-carousel ${darkMode ? "dark" : ""}`}>
                  {mainFilesSmall?.map((file, index) => (
                    <Media
                      key={index}
                      file={file}
                      onMouseOver={() => setMainFileBig(file)}
                    />
                  ))}
                </div>

                <div style={{fontSize: "1.5vh"}}>Prendas vistas</div>
                <div className="container-image" id="container-image" onClick={() => { setVisibleHand(false); }}>
                  
                  <div className={`image-thumbnails ${darkMode ? "dark" : ""}`}>
                    {historyProducts?.map((historyProduct, index) => (
                      <img
                        key={index}
                        src={getImageProduct(historyProduct)}
                        alt={`Thumbnail ${index + 1}`}
                        onClick={() => onHistoryProductClick(historyProduct)}
                      />
                    ))}
                  </div>
                  <div className="hand">
                    <HandSwipeAnimation visible={visibleHand}></HandSwipeAnimation>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid xs={isMobile ? 6 : 4}>
              <div className={`column-product-info ${darkMode ? "dark detail" : "detail"}`}>
                <div style={{ fontSize: "2vh", fontWeight: "normal", height: "4vh", marginTop: "1vh"}}>{product?.name}</div>
                <div style={{ fontSize: "1.5vh", marginTop: "0.5vh" }}>Código: {product?.sku}</div>
                <div style={{ marginTop: "1.5vh", fontSize: "1.5vh", display: "inline-flex" }}>
                  {stock > 0 ? <div style={{ color: "#50f757" }}>{`Stock `} </div> : <div style={{ color: "#f74242" }}>Sin stock</div>}
                  {stock > 0 && <div style={{ marginLeft: "5px" }}> {` (${stock} disp.)`}</div>}
                </div>
                <div ref={containerRef} className={`description-tip ${hasOverflow ? "animate" : ""}`}>
                  <span ref={textRef} className="scroll-text">{product?.description}</span>
                </div>

                <div className="price">
                  {sellPrice ? `${sellPrice} €` : ""}
                </div>
                {containRetail &&
                  <ProductColoursAndSize retail={retailProduct} setSelectedProduct={setSelectedProduct} />
                }


                <div className="btnShare">
                  <button
                    className="main-button enabled"
                    onClick={handleOpen}
                  >
                     <ShareIcon style={{marginRight:"10px", height:"35px", width:"35px"}} /> Compartir
                    </button>

                  <Modal
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    <Box
                      className={`${darkMode ? "dark-modal" : "white-modal"}`}
                      style={{
                        position: "absolute",
                        top: "30%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "white",
                        padding: "1em",
                        maxWidth: "90%",
                        minWidth: "60%",
                        minHeight: "30%",
                        overflowY: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "20px"
                      }}
                    >
                      <h2 id="simple-modal-title">Ingresa tu email</h2>

                      <CostumInput
                        style={`${darkMode ? "dark-style" : ""}`}
                        id={"email"}
                        placeholder="Email"
                        icon="icon-mail"
                        handleChange={handleChangeEmail}
                      ></CostumInput>

                      <div className="btnContainer">
                        <span className="btnCancel">
                          <MainButton
                            legend="cancelar"
                            handleClick={handleClose}
                            state={`${darkMode ? "dark-dark" : "enabled"}`}
                          />
                        </span>
                        <span className="btnSend">
                          <MainButton
                            legend="enviar"
                            handleClick={handleSendEmail}
                            state={`${darkMode ? "dark" : "enabled"}`}
                          />
                        </span>
                      </div>
                    </Box>
                  </Modal>
                </div>
              </div>
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
              <div className={`column-product-info recomendation ${darkMode ? "dark" : ""}`}>

                <div style={{fontSize: "1.5vh"}}>Productos recomendados</div>
                <div className={`images-related`}>
                  {productRelatedItems?.length > 0 &&
                    productRelatedItems.map((item, index) => (
                      <div className={`${darkMode ? "dark-image" : ""}`} key={index} onClick={() => handleClickRecommended(item)}>
                        <img className={`${darkMode ? "dark-image" : ""}`}
                          src={item.item_files?.[0]?.file_name}
                          alt={item.item_files?.[0]?.file_name}
                        />
                      </div>
                    ))}
                </div>

              </div>
            </Grid>
          </Grid>
        </div>
      }
      <SentEmailPopup darkMode={darkMode} open={openSentEmailPopup} handleClose={handleCloseSentEmailPopup}  />
    </div>
  );
};

export default ProductInfo;
