import { Box, Modal } from "@material-ui/core";
import MainButton from "components/MainApp/atoms/RoundedButton";
import iconSentEmail from "assets/images/sent-email.png";
import "./styles.scss";

const SentEmailPopup = ({darkMode, open, handleClose}) => {

    return (
        <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          className={`${darkMode ? "dark-modal" : "white-modal"}`}
          style={{
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "1em",
            maxWidth: "90%",
            minWidth: "60%",
            minHeight: "30%",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "20px"
          }}
        >
          <img style={{height: "70px"}} src={iconSentEmail} alt="iconSentEmail" />
          <h3 style={{marginBottom: "5px", marginTop: "10px"}} id="simple-modal-title">Enviada</h3>
          <p style={{marginBottom: "20px", marginTop: "0px"}}>La información de producto fue enviada con éxito.</p>

          <div className="btnContainer">
            <span className="btnCancel">
              <MainButton
                legend="Cerrar"
                handleClick={handleClose}
                state="enabled"
              />
            </span>
          </div>
        </Box>
      </Modal>
    )
}

export default SentEmailPopup;