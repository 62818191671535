import "react-datepicker/dist/react-datepicker.css";
import "./DateRangePicker.css";
import { forwardRef, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { formatDateToYyyyMmDd } from "helpers/Parsers";
import { es } from "date-fns/locale";
import Icon from "components/MainApp/atoms/Icon/icon";
import { TextField } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import calendarIcon from "assets/images/calendar-icon.svg";
import useWindowSize from "hooks/useWindowSize";

const DateRangePicker = ({ filters, setSelectDateRange }) => {
  const { isMobile } = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);
  const today = new Date();
  const [dateRange, setDateRange] = useState([today, today]);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);

  const [selection, setSelection] = useState([today, today]);
  const [initialValues, setInitialValues] = useState([today, today]);
  const datePickerRef = useRef();

  const isTodaySelected = 
    formatDateToYyyyMmDd(new Date(dateRange[0])) === formatDateToYyyyMmDd(today) &&
    formatDateToYyyyMmDd(new Date(dateRange[1])) === formatDateToYyyyMmDd(today);

  const updateDates = (values) => {
    const [start, end] = values;

    setStartDate(start || undefined);
    setEndDate(end || undefined);

    return ({ start, end })
  }
  
  const handleClear = () => {
    setDateRange([today, today]);
  }

  const handleCancel = () => {
    setDateRange(initialValues);
    updateDates(initialValues);

    datePickerRef.current.setOpen(false);
  }

  const handleApply = () => {
    setDateRange(selection);
    datePickerRef.current.setOpen(false);
  }

  const handleChange = (update) => {
    if(isMobile) {
      updateDates(update);
      setSelection(update)
    } else {
      setDateRange(update);
    }
  }

  useEffect(() => {
    if (filters?.dateRange?.start && filters?.dateRange?.end) {
      const start = new Date(`${filters.dateRange.start}T00:00:00`);
      const end = new Date(`${filters.dateRange.end}T23:59:59`);
      setDateRange([start, end]);
    }
  }, []);

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <div>
      <TextField
        value={value || "Rango de fecha"}
        placeholder={value || "Rango de fecha"}
        onClick={onClick}
        ref={ref}
        variant="outlined"
        className={`
          ${value ? "custom-date-input-complete" : "custom-date-input"}
          ${isOpen ? "custom-date-input-calendar-open" : ""}
        `}
        InputProps={{ readOnly: true }}
      />
      <img src={calendarIcon} />
      {value && !isTodaySelected && (
        <button className="react-date-picker__clear-button" onClick={handleClear}>
          <Icon name="remove" />
        </button>
      )}
    </div>
  ));

  useEffect(() => {
    const { start, end } = updateDates(dateRange);

    if (start && end) {
      setSelectDateRange({
        start: formatDateToYyyyMmDd(start),
        end: formatDateToYyyyMmDd(end)
      });
    }
  }, [dateRange]);

  useEffect(() => {
    setInitialValues(dateRange)
  }, [dateRange])

  // Autocomplete the end date if I only select start date 
  useEffect(() => {
    if (!isOpen && startDate && !endDate) {
      setEndDate(startDate);
      setSelectDateRange({
        start: formatDateToYyyyMmDd(startDate),
        end: formatDateToYyyyMmDd(startDate)
      });
    }
  }, [isOpen]);

  return (
    <DatePicker
      ref={datePickerRef}
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={handleChange}
      calendarStartDay={1}
      dateFormat="dd/MM/yyyy"
      customInput={<CustomDateInput />}
      locale={es}
      previousMonthButtonLabel={<ArrowBackIos />}
      nextMonthButtonLabel={<ArrowForwardIos />}
      onCalendarOpen={() => setIsOpen(true)}
      onCalendarClose={() => setIsOpen(false)}
      shouldCloseOnSelect={!isMobile}
      withPortal={isMobile}
    >
      {isMobile && (
        <div className="react-datepicker__action-buttons">
          <button onClick={handleCancel}>Cancelar</button>
          <button onClick={handleApply}>Aceptar</button>
        </div>
      )}
    </DatePicker>
  );
};

export default DateRangePicker;
