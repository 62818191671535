import style from "./SelectTypeLectun.module.scss";
import { useEffect, useRef, useState, forwardRef, memo } from "react";
import manualIcon from "./assets/manual-icon.svg";
import automaticIcon from "./assets/automatic-icon.svg";
import checkIcon from "./assets/check-icon.svg";
import { EventRepository } from "helpers/EventRepository";
import { LECTUN_TYPE } from "views/Admin/Devices/ArcActivity";
import MainAppGenericModal from "../Modals/GenericModal";
import useWindowSize from "hooks/useWindowSize";

export const SelectTypeLectun = ({
  selected,
  setSelected,
  setShow,
  setIsLoading,
  devicePickerRef
}) => {
  const { isMobile } = useWindowSize();
  const componentRef = useRef(null);
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => setShow(false), 200);
  };

  const handleChange = type => {
    if (selected !== type) {
      setSelected(type);
      localStorage.setItem("lectun-type", type);
      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
        handleClose();

        if(type === LECTUN_TYPE.AUTOMATIC) {
          devicePickerRef.current?.openRoom();
        }

        EventRepository.notificationSend({
          label: `Se cambió proceso de lectura a ${type} con éxito.`,
          type: "success"
        });
      }, 200);
    }
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (isMobile)
    return (
      <MemoizedContent
        ref={componentRef}
        styles={`${style.selectTypeLectun} ${isClosing ? style.closing : ""}`}
        selected={selected}
        handleChange={handleChange}
      />
    );

  return (
    <MainAppGenericModal
      open={true}
      content={
        <MemoizedContent
          ref={componentRef}
          styles={style.contentModal}
          selected={selected}
          handleChange={handleChange}
        />
      }
      handleClose={() => setShow(false)}
      classModalContainer={`${style.selectTypeLectunModal} ${isClosing ? style.closingModal : ""}`}
    />
  );
};

const Content = forwardRef(({ styles, selected, handleChange }, ref) => (
  <div className={styles} ref={ref}>
    <h3>Proceso de lectura</h3>
    <div>
      {[
        { icon: manualIcon, type: LECTUN_TYPE.MANUAL, label: "Manual" },
        {
          icon: automaticIcon,
          type: LECTUN_TYPE.AUTOMATIC,
          label: "Automático"
        }
      ].map(({ icon, type, label }) => (
        <button key={type} onClick={() => handleChange(type)}>
          <img src={icon} draggable={false} />
          {label}

          {selected === type && (
            <img src={checkIcon} draggable={false} className={style.check} />
          )}
        </button>
      ))}
    </div>
  </div>
));

const MemoizedContent = memo(Content);
