import i18n from "i18n";

export const ParseMovement = {

    getMovementType: (movement_tipe) => {
        switch (movement_tipe) {
            case "IN":
                return i18n.t("Entrada");
            case "OUT":
                return i18n.t("Salida");
            case "MOVE":
                return i18n.t("Movimiento");               
            default:
                return '';
                
        }
    },

    getOrderType: (order_type) => {

        switch (order_type) {
            case "REL":
                return i18n.t("Relocacion");
            case "PUR":
                return i18n.t("Compra");
            case "REQ":
                return i18n.t("Pedido");
            case "SAL":
                return i18n.t("Venta");
            case "PRE":
                return i18n.t("Preparacion");
            case "REC":
                return i18n.t("Recepcion");
            default:
                return '';
                break;
        }
    },

    getOrderKlass: (order_klass) => {

        switch (order_klass) {
            case "BAS":
                return i18n.t("Basico");
            case "REQ":
                return i18n.t("Pedido");
            case "ADJ":
                return i18n.t("Ajuste");
            case "SALE":
                return i18n.t("Venta");
            case "PUR":
                return i18n.t("Compra");
            case "REF":
                return i18n.t("Devolucion");
            case "PRO":
                return i18n.t("Produccion");
            case "BRE":
                return i18n.t("Rotura");
            case "LOSS":
                return i18n.t("Pérdida desconocida");
            default:
                return '';
                break;
        }
    }
}
