const prepTypeMapping = {
  BAS: "Preparacion",
  REQ: "Preparacion",
  SALE: "De venta",
  ADJ: "Ajuste de inventario",
  BRE: "Rotura",
  LOSS: "Pérdida desconocida",
};

export default prepTypeMapping;
