import "./GuardActivityViewDesk.scss";
import React from "react";
import i18n from "i18n";
import { useStore } from "store/context";
import useTokenExpirationCheck from "hooks/useTokenExpirationCheck";
import { useAlerts } from "hooks/useAlerts";
import { Container } from '@material-ui/core';
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import GuardItem from "shared/components/guard/guard-item/GuardItem";
import GuardItemDetail from "shared/components/guard/guard-item-detail/GuardItemDetail";
import FilterAlerts from "components/MainApp/organisms/FiltersAlerts";
import ModalAlerts from "components/MainApp/organisms/Modals/ModalAlerts/ModalAlerts";
import PaginationAlert from "components/MainApp/atoms/PaginationAlert/PaginationAlert";
import clockIcon from "assets/images/clock-icon.svg";
import notAlertsIlustration from "assets/images/not-alerts-ilustration.svg";
import downArrowIcon from "assets/images/down-arrow-icon.svg";
import alarmIcon from "assets/images/alarm-notification-icon.svg";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { formatDateAndHour } from "helpers/Parsers";

const GuardActivityViewDesk = () => {
    const [{ alertList }, dispatch] = useStore();
    const { alerts, fetch, order, modals, pagination, filters, devices } = useAlerts();

    const { date, hour } = formatDateAndHour(alerts.lastUpdate);

    const minutesCheckTokenTime = 5 ; // 5 minutos
    useTokenExpirationCheck(minutesCheckTokenTime);
    
    const itemsHeadTable = [
        {
            text: "Fecha",
            event: order.createEvent("created_at"),
            state: order.state.created_at
        },
        { text: "Hora", event: () => {} },
        {
            text: "Dispositivo",
            event: order.createEvent("device__name"),
            state: order.state.device__name
        },
        {
            text: "Ubicación",
            event: order.createEvent("device__location__name"),
            state: order.state.device__location__name
        },
        {
            text: "Estado",
            event: order.createEvent("status"),
            state: order.state.status
        }
    ];

    const renderOrderIcon = (state) => {
        switch(state) {
            case order.rules.DEFAULT: return <SwapVertIcon />
            case order.rules.DESC: return <img src={downArrowIcon} />
            case order.rules.ASC: return <img src={downArrowIcon} style={{ transform: "rotate(180deg)" }}/>
        }
    }

    const content = (
        <>
            <Container className="guard-view-desk" style={{ display: alerts.selected ? "none" : "block" }}>
                <FilterAlerts filters={filters} devices={devices} />

                {alerts.list.length > 0 ? (
                    <section className="alarm-container">
                        <div className="alarm-container__count">
                            <h4>Total de alertas ({alerts.total})</h4>
                            {alerts.lastUpdate && (
                                <div>
                                    <img src={clockIcon} />
                                    <p>Última Alerta: {date} {hour}</p>
                                </div>
                            )}
                        </div>

                        <ul className="alarm-container__thead">
                            {itemsHeadTable.map(item => (
                                <li key={item.text} onClick={item.event}>
                                    <span>
                                        {item.text}
                                        {renderOrderIcon(item.state)}
                                    </span>
                                </li>
                            ))}
                        </ul>

                        <div className="alarm-container__list">
                            {alerts.list.map(alert => (
                                <GuardItem
                                    key={alert.id}
                                    alert={alert}
                                    setAlertSelected={alerts.setSelected}
                                />
                            ))}
                        </div>

                        <PaginationAlert
                            currentPage={pagination.currentPage}
                            setCurrentPage={pagination.setCurrentPage}
                            totalPages={pagination.totalPages}
                        />
                    </section>
                    ) : (
                    !fetch.isLoading && (
                        <section className="not-alerts">
                            <img src={notAlertsIlustration} />
                            <p>No se han registrado nuevas alertas</p>
                        </section>
                    )
                )}
            </Container>
            {alerts.selected && (
                <GuardItemDetail
                    alert={alerts.selected}
                    setAlert={alerts.setSelected}
                    reloadAlerts={fetch.reload}
                />
            )}
        </>
    );

    const headerOptions = [
        {
          icon: "",
          name: "notifications-alerts",
          handler: () => {
            if(alertList.length > 0) {
              dispatch({ type: "setAlert", payload: null });
              modals.alerts.setView(true)
            }
          },
          tooltip: "Notificaciones de alertas",
          ic: alarmIcon,
          count: alertList.length
        },
      ];

    return (
        <div className={`device-detail-container ${alerts.selected ? "guard-detail-open" : ""}`}>
            <AdminLayout
                headerTitle={i18n.t("PRESIS")}
                headerOptions={headerOptions}
                content={content}
                navHidden={true}
            />
            
            {modals.alerts.view && (
                <ModalAlerts
                    isOpen={modals.alerts.view}
                    setIsOpen={modals.alerts.setView}
                    setAlertSelected={alerts.setSelected}
                    reloadAlerts={fetch.reload}
                />
            )}

            {fetch.isLoading && <Loader />}
        </div>
    );
};

export default GuardActivityViewDesk;