import "./ModalAlerts.scss";
import { useEffect, useState } from "react";
import { useStore } from "store/context";
import { GuardStatusService } from "services/GuardStatusService";
import useWindowSize from "hooks/useWindowSize";
import MainAppGenericModal from "components/MainApp/atoms/Modals/GenericModal";
import Loader from "components/MainApp/atoms/Loader";
import closeIcon from "assets/images/close-icon.svg";
import alarmIcon from "assets/images/alarm-icon.svg";
import clockIcon from "assets/images/clock-icon.svg";
import locationIcon from "assets/images/location-icon-small.svg";
import skuIcon from "assets/images/sku-icon-small.svg";
import arrowIcon from "assets/images/arrow-icon-small.svg";
import backArrow from "assets/images/back-arrow.svg";
import { formatDateAndHour } from "helpers/Parsers";
import ModalProcessAlert from "../ModalProcessAlert/ModalProcessAlert";
import deviceIcon from "assets/images/device-alert-icon.svg";
import Checkbox from "components/Auth/atoms/forms/CheckboxCostum";

const ModalAlerts = ({ isOpen, setIsOpen, setAlertSelected, reloadAlerts }) => {
  const { isMobile } = useWindowSize();
  const [{ alertList }] = useStore();
  const [alerts, setAlerts] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAlerts, setSelectedAlerts] = useState([]);
  const [isOpenProcess, setIsOpenProcess] = useState(false);

  const { date, hour } = formatDateAndHour(lastUpdate);

  const devices = [
    ...new Map(alerts.map(alert => [alert.device.id, alert.device])).values()
  ];

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleProcess = () => {
    setIsOpenProcess(true);
  };

  const handleBackProcess = () => {
    setIsOpenProcess(false);
  };

  const addAlertToSelection = alert => {
    const alreadyExist = selectedAlerts.find(
      selected => selected.id === alert.id
    );

    alreadyExist
      ? setSelectedAlerts(prev => prev.filter(sel => sel.id !== alert.id))
      : setSelectedAlerts(prev => [...prev, alert]);
  };

  const handleClickCard = alert => {
    setIsOpen(false);
    setAlertSelected(alert);
  };

  const handleSelect = ({ event, alert }) => {
    event.stopPropagation();
    addAlertToSelection(alert);
    return;
  };

  const isSelected = id =>
    selectedAlerts.some(selectedAlert => selectedAlert.id === id);

  const contentPopUp = (
    <section className="modal-alerts">
      <img src={isMobile ? backArrow : closeIcon} onClick={handleClose} />
      <header>
        <img src={alarmIcon} />
        <div>
          <h4>Alerta</h4>
          <p>
            <img src={clockIcon} /> {date} {hour}
          </p>
        </div>
      </header>
      <div className="modal-alerts__items">
        {devices.map(device => (
          <>
            <div className="modal-alerts__items__device">
              <picture>
                <img src={deviceIcon} />
              </picture>
              <h4>{device.name}</h4>
            </div>

            {alerts
              .filter(alert => alert.device.id === device.id)
              .map(alert => (
                <article
                  key={alert.id}
                  onClick={() => handleClickCard(alert)}
                  className={
                    isSelected(alert.id) ? "modal-alerts__items--active" : ""
                  }
                >
                  <span onClick={event => handleSelect({ event, alert })}>
                    <Checkbox checked={isSelected(alert.id)} />
                  </span>
                  <div>
                    <div>
                      <h3>{alert?.tag?.variables?.name}</h3>
                      <h4>
                        <img src={locationIcon} />
                        {alert?.device?.location?.name}
                      </h4>
                    </div>
                    <div>
                      <img src={skuIcon} />
                      <p>SKU {alert?.tag?.sku}</p>
                      <p>{alert?.tag?.rfid}</p>
                    </div>
                  </div>
                  <img src={arrowIcon} />
                </article>
              ))}
          </>
        ))}
      </div>
      <footer>
        <button onClick={handleClose}>Cancelar</button>
        <button onClick={handleProcess} disabled={selectedAlerts.length < 1}>
          Procesar alarmas
        </button>
      </footer>
    </section>
  );

  useEffect(() => {
    if (alertList.length > 0) {
      setIsLoading(true);

      const promises = alertList.map(alert =>
        GuardStatusService.getAlarmGuardStatusById(alert)
      );

      Promise.all(promises)
        .then(responses => setAlerts(responses))
        .finally(() => setIsLoading(false));
    }
  }, [alertList]);

  useEffect(() => {
    if (alerts.length > 0) {
      setLastUpdate(new Date(alerts[alerts?.length - 1].created_at));
    }
  }, [alerts]);

  if (alertList.length === 0) return <></>;

  if (isLoading) return <Loader />;

  return (
    <>
      {!isOpenProcess && (
        <MainAppGenericModal
          open={isOpen}
          content={contentPopUp}
          handleClose={handleClose}
          classContainer="modal-alerts-container"
          classModalContainer="modal-alerts-modal-container"
        />
      )}
      <ModalProcessAlert
        isOpen={isOpenProcess}
        alert={selectedAlerts}
        handleBack={handleBackProcess}
        handleClose={handleClose}
        reloadAlerts={reloadAlerts}
      />
    </>
  );
};

export default ModalAlerts;
