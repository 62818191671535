import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import useRequest from "hooks/useRequest";

import { UserService } from "services/UserService";

import { EventRepository } from "helpers/EventRepository";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import GenericErrorModal from "components/MainApp/atoms/Modals/GenericErrorModal";

import RoleForm from "../../components/RoleForm"
import "./styles.scss";
import UsersView from "../..";

const initialRole = {
    id: '',
    name: '',
    description: '',
    permissions: [],
    permissions_id: [],
}


const EditRoleView = (props) => {
  const history = useHistory();
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();

  const [role, setRole] = useState(initialRole);
  const [id, setId] = useState();
  const [deleteErrorModal, setdeleteErrorModal] = useState(false);
  const [deleteErrorMsg, setdeleteErrorMsg] = useState("");

  useEffect(() => {
    if (!props.match.params.key) return;
    beforeSubmit();
    const id = props.match.params.key;
    setId(id);
    UserService.role(id)
      .then((role) => {
        afterSubmit();
        let _role = {...role}
        _role.permissions = _role.permissions.map(p => p.name)
        setRole(_role);
      })
      .catch((error) => {
        afterSubmit();
      });
  }, []);

  useEffect(() => {
    setTimeout(positionCheckboxPopup, 5);
  }, [role]);

  const analizeErrors = (message, errors = {}) => {
    if (Object.keys(errors).length === 0) {
      EventRepository.notificationSend({
        label: message,
        type: "error",
      });
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    beforeSubmit();
    let _role = {...role}
    if (_role.permissions[0]?.id) {
      _role.permissions_id = _role.permissions.map(p => p.id)
    }else{
      _role.permissions_id = _role.permissions
    }

    // _role.permissions_id = [1, 2]
    UserService.updateRole(_role.id, _role)
        .then((response) => {
          afterSubmit();
          history.push('/admin/roles')
          EventRepository.notificationSend({
            label: 'El rol se modificó correctamente',
            type: "success",
          });
        })
        .catch((error) => {
          afterSubmit();
          dealWithError(error, "generic.error", (message, errors) => {
            analizeErrors(message, errors);
          });
        });
  };

  const handleGoBack = () => {
    history.push('/admin/roles?from=edit')
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let _role = { ...role };
    _role[name] = value;

    setRole(_role);
  };

  const validateContinue = () => {
    return role.name.trim().length && role.description && role.description.trim().length && role.permissions.length > 0;
  };

  const handleSelect = (scope) => {
    const _role = { ...role };

    if (_role.permissions.includes(scope)) {
      _role.permissions = _role.permissions.filter((s) => s !== scope);
    } else {
      _role.permissions.push(scope);
    }
    setRole(_role);
  };

  const handleSelectMobile = (scope, clickedEl) => {
    const _role = { ...role };

    if(_role.permissions.includes(clickedEl?.id)){
      _role.permissions = _role.permissions.filter((s) => s !== clickedEl?.id);
    }else{
      _role.permissions.push(clickedEl?.id);
    }
    validateContinue();
    setRole(_role);
  }

  const handleDelete = () => {
    beforeSubmit()
    UserService.deleteRole(role.id)
      .then((rta) => {
        afterSubmit();
        history.push(`/admin/roles`);
        EventRepository.notificationSend({
          label: 'El rol se elimino correctamente',
          type: "success",
        });
      })
      .catch((error) => {
        afterSubmit();
        setdeleteErrorModal(true);
        setdeleteErrorMsg("No se puede eliminar la sucursal");
      });
  }

  const handleClose = () => {
    return true;
  };

  const positionCheckboxPopup = () => {
    const el1 = document.querySelector('.multi-select-wrapper');
    const popUp = document.querySelector('.MuiPaper-rounded');
    if (el1 && popUp) {
      popUp.style.setProperty('left', `${el1.offsetLeft - 1}px`, 'important');
    }
  }

  const handleOpen = () => {
    setTimeout(positionCheckboxPopup, 5);
    return true;
  };

  const headerOptions = [
    {
      icon: "icon-iconos_eliminar",
      name: "delete",
      handler: handleDelete,
      tooltip: "Eliminar Rol",
    }
  ];
  const content = (
    <div className={"add-user-content-wrapper"}>
      <div className={"add-user-inner"}>
          <RoleForm
            role={role}
            handleSelect={handleSelect}
            handleSelectMobile={handleSelectMobile}
            handleChange={handleChange}
            handleClose={handleClose}
            handleOpen={handleOpen}
            handleSubmit={handleUpdate}
            errors={errors}
            continueEnabled={validateContinue()}
            mode={'Add'}
        />
       </div>
    </div>
  );
  return (
    <div className="add-user-container">
      <AdminLayout
        headerTitle={i18n.t(`Detalle de rol`)}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      <GenericErrorModal
        open={deleteErrorModal}
        handleClose={() => setdeleteErrorModal(false)}
        error={deleteErrorMsg}
      ></GenericErrorModal>
      {loading && <Loader />}
    </div>
  );
};

export default EditRoleView;
