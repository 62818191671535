import { useState } from "react";

export const usePagination = (initialList, initialPage, initialPageSize) => {
  const [list, setlist] = useState(initialList);
  const [currentPage, setcurrentPage] = useState(initialPage);
  const [pageSize, setpageSize] = useState(initialPageSize);

  const getPageQuantity = (l, ps) => {
    const listSize = l.length;
    const pageQ = Math.ceil(listSize / ps);
    return pageQ;
  };

  const [pageQuantity, setpageQuantity] = useState(
    getPageQuantity(initialList, initialPageSize)
  );

  const getNewPage = (l, cp, pz) => {
    const listSize = l.length;
    let start;
    if (cp > 0) {
      start = pz * (cp - 1);
    } else {
      start = 0;
    }
    const finish = start + pz;
    let newList;
    if (finish < listSize) {
      newList = l.slice(start, finish);
    } else {
      newList = l.slice(start);
    }
    return newList;
  };

  const [pageList, setPageList] = useState(
    getNewPage(initialList, initialPage, initialPageSize)
  );

  const changePage = (newCurrentPage) => {
    const newPageList = getNewPage(list, newCurrentPage, pageSize);
    setcurrentPage(newCurrentPage);
    setPageList(newPageList);
  };

  const changePageSize = (newPageSize, newCurrentPage = 0) => {
    const newPageList = getNewPage(list, newCurrentPage, newPageSize);
    setcurrentPage(newCurrentPage);
    setpageSize(newPageSize);
    setPageList(newPageList);
  };

  const changeList = (newList, newCurrentPage, newPageSize) => {
    setlist(newList);
    setpageQuantity(getPageQuantity(newList, newPageSize));
    const newPageList = getNewPage(newList, newCurrentPage, newPageSize);
    setcurrentPage(newCurrentPage);
    setpageSize(newPageSize);
    setPageList(newPageList);
  };

  const restartPageList = () => {
    setPageList(getNewPage(initialList, initialPage, initialPageSize))
  }

  return [
    list,
    currentPage,
    pageSize,
    pageList,
    pageQuantity,
    changePageSize,
    changePage,
    changeList,
    restartPageList,
  ];
};

export default usePagination;
