import React from 'react'
import Card from '../Card';
import './styles.scss';

const DeliveredOrdersCard = ({items}) => {
    return <div className={'delivered-orders-card-container'}>
        <Card>
            <div className={'title-container'}>
                <h1 className={'dashboard-card-title'}>Órdenes entregadas</h1>
            </div>

            <div className={'delivered-orders-table'}>
                {items.map((item, i) => {
                    if( i <= 5) {
                        return <div key={item.client} className={'delivered-orders-item'}> 
                            <div className={`${i === 0 ? 'delivered-orders-item-client-first' : 'delivered-orders-item-client'}`}>{item.client}</div>
                            <div className={`${i === 0 ? 'delivered-orders-item-orders-first' : 'delivered-orders-item-orders'}`}>{i=== 0 && <span>ordenes</span>} {item.orders} {i!== 0 && 'ordenes'}</div>
                        </div>
                    }
                })}
            </div>
        </Card>
    </div>
};

export default DeliveredOrdersCard;