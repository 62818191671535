import style from "./TagReadsArc.module.scss";
import tagIcon from "assets/images/tag-icon.svg";

export const TagsReadsArc = ({ epcsReads }) => {
  return (
    <div className={style["tags_reads_arc"]}>
      <article>
        <div>
          <picture>
            <img src={tagIcon} alt="tag icon" />
          </picture>
          <h4>Tags leídos</h4>
        </div>
        <span>{epcsReads}</span>
      </article>
    </div>
  );
};
