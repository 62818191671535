import { useEffect } from "react";
import { useStore } from "store/context";
import useWindowSize from "hooks/useWindowSize";
import GuardActivityViewDesk from "./versions/desktop/GuardActivityViewDesk";
import GuardActivityViewMobile from "./versions/mobile/GuardActivityViewMobile";

const GuardActivityView = () => {
  const { isMobile } = useWindowSize();
  const [{}, dispatch] = useStore();

  useEffect(() => {
    return () => {
      dispatch({ type: "setAlertList", payload: [] });
    }
  }, [])

  return isMobile ? <GuardActivityViewMobile /> : <GuardActivityViewDesk />;
};

export default GuardActivityView;
