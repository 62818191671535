import { LECTUN_TYPE } from "views/Admin/Devices/ArcActivity";
import "./ActionButtonsArc.scss";
import { useEffect } from "react";

export const ActionButtonsArc = ({
  cleanScrenn,
  handleBack,
  handleSubmit,
  groupedProducts,
  showView,
  typeLectun
}) => {
  const DISABLE_SUBMIT =
    (!groupedProducts.length && !showView.success) ||
    typeLectun === LECTUN_TYPE.AUTOMATIC;
  const DISABLE_BACK = !groupedProducts.length;

  // Ajusta el tamaño en base al tamaño de sidebar (expanded)
  useEffect(() => {
    const gridItemBottom = document.querySelector(".ActionButtonsArc");
    const sidebarComponent = document.querySelector(".sidebar-container");

    const updateLeftPosition = () => {
      gridItemBottom.style.left = `${sidebarComponent.clientWidth}px`;
      gridItemBottom.style.width = `calc(100% - ${sidebarComponent.clientWidth}px) `;
    };

    let debounceTimeout;

    const resizeObserver = new ResizeObserver(() => {
      clearTimeout(debounceTimeout);

      debounceTimeout = setTimeout(() => {
        updateLeftPosition();
      }, 0);
    });

    resizeObserver.observe(sidebarComponent);

    return () => {
      resizeObserver.disconnect();
      clearTimeout(debounceTimeout);
    };
  }, []);

  return (
    <footer className="ActionButtonsArc">
      <div>
        {!showView.success && (
          <button
            onClick={showView.confirmation ? handleBack : cleanScrenn}
            disabled={DISABLE_BACK}
          >
            {showView.confirmation ? "Volver" : "Borrar"}
          </button>
        )}
        <button
          onClick={handleSubmit}
          disabled={DISABLE_SUBMIT}
          className={showView.success ? "ActionButtonsArc__success-button" : ""}
        >
          {showView.confirmation
            ? "Finalizar"
            : showView.success
            ? "Ir al inicio"
            : showView.initial
            ? "Procesar"
            : ""}
        </button>
      </div>
    </footer>
  );
};
