import i18n from "i18n";

const mainMenuItems = [
  {
    title: i18n.t("Configuraciones"),
    arrow: true,
    to: undefined,
    toStep: 1,
    permission: 'all',
  },
  {
    title: i18n.t("Ubicación Activa"),
    arrow: true,
    to: "/admin/active-node",
    permission: 'all',
  },
  /*{
    title: i18n.t("Contactos"),
    arrow: true,
    to: "/admin/config",
    permission: 'all',
  },*/
  {
    title: i18n.t("Mi Cuenta"),
    arrow: true,
    to: "/admin/account",
    permission: 'all',
  },
];

export const step0 = {
  items: mainMenuItems,
  headerTitle: "Más opciones",
  navHidden: false,
  goBackTo: 0,
};
