import React from 'react';
import ReactDOM from 'react-dom';

import "./i18n";

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Router } from 'react-router-dom';
import history from "./history";
import { Experimental_CssVarsProvider, experimental_extendTheme } from '@mui/material';

const theme = experimental_extendTheme({
	colorSchemes: {
		light: {
			palette: {
				primary: {
					main: "#3c7af5",
				},
			},
		},
		dark: {
			palette: {
				primary: {
					main: "#3c7af5",
				},
			},
		},
	},
});

ReactDOM.render(
	<Router history={history}>
		<Experimental_CssVarsProvider theme={theme}>
			<App />
		</Experimental_CssVarsProvider>
	</Router>,
	document.getElementById('root')
);

var orientationOrigin;
const isMobi = /Mobi/i.test(navigator.userAgent);
window.addEventListener('DOMContentLoaded', (event) => {
	// Call the function to change to portrait orientation

});

window.addEventListener('load', function () {
	if (window.matchMedia("(orientation: landscape)").matches) {
		orientationOrigin = "landscape";
		if (isMobi) {
		//	showOrientationNotSupported();
		}
	} else {
		orientationOrigin = "portrait";
	}
});

function reloadCSS() {
	// Seleccionar todos los elementos <link> que están cargando archivos CSS
	const links = document.querySelectorAll('link[rel="stylesheet"]');

	links.forEach(link => {
		// Obtener la URL actual del CSS
		const href = link.getAttribute('href');

		// Agregar un parámetro único para evitar caché (usando la marca de tiempo)
		const newHref = href.split('?')[0] + '?reload=' + new Date().getTime();

		// Actualizar el atributo href con la nueva URL
		link.setAttribute('href', newHref);
	});
}


window.addEventListener('orientationchange', () => {

	setTimeout(() => {
		showOrientationNotSupported();
		reloadCSS();
	}, 200);

});

function showOrientationNotSupported() {
	if (window.matchMedia("(orientation: landscape)").matches) {
		var div = document.createElement('div');
		div.id = "div-orientation-not-supported";
		div.style.position = 'fixed';
		div.style.top = '0';
		div.style.left = '0';
		div.style.width = '100vw';
		div.style.height = '100vh';
		div.style.backgroundColor = 'rgba(0, 0, 0, 0.9)';
		div.style.color = 'white';
		div.style.display = 'flex';
		div.style.justifyContent = 'center';
		div.style.alignItems = 'center';
		div.style.zIndex = '1000';
		div.textContent = 'Modo apaisado no soportado. Por favor, rote su dispositivo';

		const iconRotation = document.createElement('div');
		iconRotation.className = "rotate-icon-orientation";
		div.appendChild(iconRotation);
		document.body.appendChild(div);
	} else {
		sessionStorage.setItem('pageLoaded', 'false');
		var div = document.getElementById('div-orientation-not-supported');
		if (div) {
			div.remove();
		}
	}

}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
