import "./styles.scss";
import React, { useState, useEffect, useCallback, useRef } from "react";
import i18n from "i18n";
import useRequest from "hooks/useRequest";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import { Container, Grid, List } from "@material-ui/core";
import { WSService } from "services/WSServices";
import ListItemProductArcTag from "shared/components/list-item-product-arc-tag/ListItemProductArcTag";
import { AuthService } from "services/AuthService";
import { TagService } from "services/TagService";
import { EventRepository } from "helpers/EventRepository";
import { OrderService } from "services/OrderService";
import { StockService } from "services/StockService";
import { EmptyArcTags } from "shared/components/empty-arc-tags/EmptyArcTags";
import { ActionButtonsArc } from "shared/components/action-buttons-arc/ActionButtonsArc";
import { SuccessViewArc } from "shared/components/success-view-arc/SuccessViewArc";
import DeviceReadPicker from "components/Functionals/DeviceReadPicker/DeviceReadPicker";
import ToggleDeviceRead from "components/Functionals/ToggleDeviceRead/ToggleDeviceRead";
import { TagsReadsArc } from "shared/components/tag-reads-arc/TagReadsArc";
import manualLectunIconWhite from "assets/images/manual-icon-white.svg";
import manualLectunIconBlue from "assets/images/manual-icon-blue.svg";
import autoLectunIconWhite from "assets/images/automatic-icon-white.svg";
import autoLectunIconBlue from "assets/images/automatic-icon-blue.svg";
import { SelectTypeLectun } from "components/MainApp/atoms/SelectTypeLectun/SelectTypeLectun";
import useWindowSize from "hooks/useWindowSize";

let globalChannel = null;
let arrayTags = [];

export const LECTUN_TYPE = {
  MANUAL: "MANUAL",
  AUTOMATIC: "AUTOMÁTICO"
}

const EPC_TO_FINISH_AUTOMATIC = "1"

const ArcActivityView = props => {
  const [channel, setChannel] = useState(false);
  const [arrayTagsSaving, setArrayTagsSaving] = useState([]);
  const [arrayEpcsReads, setArrayEpcsReads] = useState([]);
  const [uniqueTotalEpcsReads, setUniqueTotalEpcsReads] = useState(0);
  const [tagsOriginDestination, setTagsOriginDestination] = useState([]);
  const [deviceSerial, setDeviceSerial] = useState("");
  const [showView, setShowView] = useState({ initial: true, confirmation: false, success: false });
  const [groupedProducts, setGroupedProducts] = useState([]);
  const [tabActive, setTabActive] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [numberOrders, setNumberOrders] = useState(null);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1090);

  const [typeLectun, setTypeLectun] = useState(localStorage.getItem("lectun-type") || LECTUN_TYPE.MANUAL);
  const [showSelectType, setShowSelectType] = useState(false);
  const [executeAutomatic, setExecuteAutomatic] = useState(false);
  const typeLectunRef = useRef(typeLectun);
  const executeAutomaticRef = useRef(executeAutomatic);
  const { isMobile } = useWindowSize();
  
  const { loading, beforeSubmit, afterSubmit } = useRequest();
  const devicePickerRef = useRef();

  const FIRST_DESTINATION = groupedProducts[0]?.name || null;
  const SECOND_DESTINATION = groupedProducts[1]?.name || null;

  const FIRST_DESTINATION_EPC_COUNT = groupedProducts[0]?.epcs.length;
  const SECOND_DESTINATION_EPC_COUNT = groupedProducts[1]?.epcs.length;

  const showInitialView = () => setShowView({ initial: true, confirmation: false, success: false })
  const showConfirmationView = () => setShowView({ initial: false, confirmation: true, success: false })
  const showSuccessView = () => setShowView({ initial: false, confirmation: false, success: true })

  const handleChangeTab = newTab => setTabActive(newTab);

  useEffect(() => {
    const totalEpcs = arrayTagsSaving.reduce(
      (total, tag) => total + tag.epcs.length,
      0
    );
    setUniqueTotalEpcsReads(totalEpcs);
    if (totalEpcs > 0 && deviceSerial) {
      arrayEpcsReads?.forEach(epc => {
        getTagOriginDestination(epc, deviceSerial);
      });
    }
  }, [arrayTagsSaving]);

  const getTagOriginDestination = epc => {
    if (deviceSerial) {
      // comprobamos que no esté en tagsOriginDestination
      const existTag = tagsOriginDestination.find(t => t.epc === epc);
      if (!existTag) {
        // console.log("-3333 buscamos y agregamos tag", epc);
        StockService.tag_origin_destination(epc, deviceSerial)
          .then(response => {
            setTagsOriginDestination(prevTags => [...prevTags, response]);
          })
          .catch(error => {
            console.error(error.message);
          });
      }
    }
  };

  const receiveEvent = useCallback(payload => {
    if(showView.confirmation) return;
    
    const ws = WSService.getInstance();

    if (payload.method === "EVENT" && payload.room && ws.inRoom(payload.room)) {
      setDeviceSerial(payload.room);

      let epc = payload.params["epc"];

      if(
        !executeAutomaticRef.current &&
        typeLectunRef.current === LECTUN_TYPE.AUTOMATIC &&
        epc === EPC_TO_FINISH_AUTOMATIC
      ) {
        arrayTags.length > 0 && setExecuteAutomatic(true);
        return;
      };

      let existEPC = false;
      setArrayEpcsReads(currentArrayEpcsReads => {
        if (currentArrayEpcsReads.length > 0) {
          existEPC = currentArrayEpcsReads.some(epcRead => epcRead == epc);
          
          if (!existEPC) currentArrayEpcsReads.push(epc);
        } else {
          currentArrayEpcsReads.push(epc);
          getTagOriginDestination(epc, payload.room);
        }
        return currentArrayEpcsReads;
      });

      if (!existEPC) {
        TagService.itemFromTag(epc)
          .then(item => {
            const tag = {
              epcs: [epc],
              sku: item.sku,
              product: item
            };
            const tagFound = arrayTags.find(t => t.sku === item.sku);
            if (tagFound) {
              tagFound.epcs.push(epc);
            } else if (epc !== EPC_TO_FINISH_AUTOMATIC) {
              arrayTags.push(tag);
            }
            updateListTags();
          })
          .catch(error => {
            console.error("Tag not found:", error);
          });
      }
    }
  }, []);

  useEffect(() => {
    if(executeAutomatic) {
      setIsLoading(true);
      devicePickerRef.current?.closeRoom();
      handleApply();
    }
  }, [executeAutomatic])

  const updateListTags = () => {
    let uniqueTags = arrayTags.filter(
      (value, index, self) => index === self.findIndex(t => t.sku === value.sku)
    );

    setArrayTagsSaving([...uniqueTags]);
  };

  useEffect(() => {
    globalChannel = null;

    const ws = WSService.getInstance();
    ws.listen("message", receiveEvent);

    return () => {
      if (globalChannel) {
        //closeRoom();
      }
      ws.removeListener("message");
    };
  }, []);

  useEffect(() => {
    globalChannel = channel;
  }, [channel]);

  useEffect(() => {
    typeLectunRef.current = typeLectun;
  }, [typeLectun]);

  useEffect(() => {
    executeAutomaticRef.current = executeAutomatic;
  }, [executeAutomatic]);

  const cleanScrenn = () => {
    setIsLoading(true);

    arrayTags = [];
    setArrayTagsSaving([]);
    setArrayEpcsReads([]);
    setTagsOriginDestination([]);
    setTabActive(null);
    setExecuteAutomatic(false);
    
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };

  const handleBackConfirmation = () => {
    setIsLoading(true);
    showInitialView();
    devicePickerRef.current?.openRoom();

    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }

  const handleApply = () => {
    // borramos duplicados de tagsOriginDestination con el mismo epc
    let tagsOriginDestinationUnique = tagsOriginDestination.filter((value, index, self) =>
      index === self.findIndex((t) => ( t.epc === value.epc))
    )

    // obtenemos el location del primer elemento de tagsOriginDestination
    const location = tagsOriginDestinationUnique[0]?.destination;

    if (!location) {
      EventRepository.notificationSend({
        label: "No hay una ubicación destino",
        type: "error"
      });
      setIsLoading(false);
      setExecuteAutomatic(false);
      return;
    }

    if (arrayTagsSaving.length == 0) {
      EventRepository.notificationSend({
        label: "No hay tags para ubicar",
        type: "error"
      });
      setIsLoading(false);
      setExecuteAutomatic(false);
      return;
    }
    let listTags = [];
    arrayTagsSaving.forEach(tag => {
      listTags.push(...tag.epcs);
    });
    //  console.log(listTags)
    let params = {
      rfid: listTags,
      // "location": selectedLocation.id,
      "location": location,
      "node": AuthService.getCurrentNodeCode(),
      "tagsOriginDestination": tagsOriginDestinationUnique,
    }
    beforeSubmit();
    OrderService.relocationSimplifedMove(params)
      .then(response => {
        EventRepository.notificationSend({
          label: "Tags procesados con éxito.",
          type: "success"
        });
        afterSubmit();
        cleanScrenn();
        showSuccessView();
        setNumberOrders([...response.in_orders])
      })
      .catch(error => {
        console.error(error.message);
        EventRepository.notificationSend({
          label: "Error al ubicar los tags",
          type: "error"
        });
        setExecuteAutomatic(false);
        afterSubmit();
      })
      .finally(() => {
        if(typeLectun === LECTUN_TYPE.AUTOMATIC) {
          setTimeout(() => {
            showInitialView();
            devicePickerRef.current?.openRoom();
          }, 2000);
        }
      })
  };

  const handleSubmit = () => {
    if(showView.initial) {
      setIsLoading(true);
      showConfirmationView();
      devicePickerRef.current?.closeRoom();

      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    }

    if(showView.confirmation) {
      handleApply();
    } 
    
    if(showView.success) {
      showInitialView();
      devicePickerRef.current?.closeRoom()
    }
  }

  const handleGoBack = () => {
    history.push(`/admin`);
  };

  useEffect(() => {
    const grouped = {};
  
    // Agrupar los productos por destination
    tagsOriginDestination.forEach(epcItem => {
      const { destination, destination_path_name, epc } = epcItem;
      
      if(epc === EPC_TO_FINISH_AUTOMATIC) return;
  
      // Si el destination no está en el objeto agrupado, lo inicializamos
      if (!grouped[destination]) {
        grouped[destination] = {
          name: destination_path_name,
          items: [],
          epcs: []
        };
      }
  
      // Verificar si el epc ya está en el arreglo sino se agrega
      const isEpcInGroup = grouped[destination].epcs.some(e => e === epc);
      if (!isEpcInGroup) {
        grouped[destination].epcs.push(epc);
      }
  
      // Buscar el producto que contiene este EPC
      arrayTagsSaving.forEach(product => {
        if (product.epcs.includes(epc)) {
          const isProductInGroup = grouped[destination].items.some(
            item => item.product.id === product.product.id
          );
  
          // Filtrar los epcs de product que están en grouped[destination].epcs
          const filteredEpcs = product.epcs.filter(item => grouped[destination].epcs.includes(item));
  
          if (!isProductInGroup) {
            grouped[destination].items.push({
              product: product.product,
              epcs: filteredEpcs
            });
          } else {
            // Si el producto ya está en el grupo, actualizamos su lista de epcs
            grouped[destination].items = grouped[destination].items.map(item => {
              if (item.product.id === product.product.id) {
                return {
                  ...item,
                  epcs: [...new Set([...item.epcs, ...filteredEpcs])]
                };
              }
              return item;
            });
          }
        }
      });
    });
  
    setGroupedProducts(Object.values(grouped));
  }, [tagsOriginDestination, arrayTagsSaving]);
  
  useEffect(() => {
    if (groupedProducts.length > 0 && !tabActive) {
      setTabActive(FIRST_DESTINATION);
    }
  }, [groupedProducts]);

  useEffect(() => {
    let debounceTimeout = null;

    const handleResize = () => {
      if (debounceTimeout) clearTimeout(debounceTimeout);
  
      debounceTimeout = setTimeout(() => {
        setIsWideScreen(window.innerWidth > 1090);
      }, 300);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (debounceTimeout) clearTimeout(debounceTimeout);
    };
  }, []);

  useEffect(() => {
    return () => cleanScrenn();
  }, []);

  const content = (
    <Container className="ArcActivityView__content">
      <div
        className="ArcActivityView__header" 
        style={{ display: showView.initial ? "flex" : "none" }}
      >
        <div>
          <DeviceReadPicker
            ref={devicePickerRef}
            typeRead={"READ"}
            receiveEvent={receiveEvent}
            includeType={"FLOW"}
            maxDevicesSelected={1}
            channel={channel}
            setChannel={setChannel}
          />
          <TagsReadsArc epcsReads={uniqueTotalEpcsReads} />
        </div>
        <ToggleDeviceRead
          handleToggleChange={devicePickerRef.current?.handleToggleChange}
          disableToggle={devicePickerRef.current?.disableToggle}
          channel={channel}
        />
      </div>
      
      {showView.confirmation &&
        <div className="ArcActivityView__tagsRead">
          <TagsReadsArc epcsReads={uniqueTotalEpcsReads} />
        </div>
      }

      <Grid
        container
        className={`grid-item-view-arc ${
          showView.confirmation ? "grid-item-view-arc__confirmation-view" : ""
        }`}
      >
        <Grid item xs={12}>
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item xs={12} className="grid-column-arc">
              {showView.initial &&
                <>
                  {FIRST_DESTINATION &&
                    <nav className={`
                      ArcActivityView__nav-tab
                      ${isWideScreen ? "ArcActivityView__nav-tab-wide" : ""}
                    `}>
                      <button
                        className={tabActive === FIRST_DESTINATION ? "active" : ""}
                        onClick={() => handleChangeTab(FIRST_DESTINATION)}
                        disabled={!FIRST_DESTINATION}
                      >
                        <p>Destino {FIRST_DESTINATION}</p>
                        <span>{FIRST_DESTINATION_EPC_COUNT}</span>
                      </button>
                      <button
                        className={tabActive === SECOND_DESTINATION ? "active" : ""}
                        onClick={() => handleChangeTab(SECOND_DESTINATION)}
                        disabled={!SECOND_DESTINATION}
                      >
                        <p>Destino {SECOND_DESTINATION}</p>
                        <span>{SECOND_DESTINATION_EPC_COUNT}</span>
                      </button>
                    </nav>
                  }

                  <List
                    component="div"
                    aria-labelledby="nested-list-subheader"
                    className={`ArcActivityView__list ${isWideScreen ? "ArcActivityView__list-flex" : ""}`}
                  >
                    {groupedProducts.length > 0 ? (
                      <>
                        {[FIRST_DESTINATION, SECOND_DESTINATION].map((destinationName) => {
                          const tabIsActive = tabActive === destinationName;

                          const renderGroupItems = (groupName) => {
                            const group = groupedProducts.find(group => group.name === groupName);
                            return group?.items.map((tag, index) => {
                              const epcsOriginDestination = tag.epcs.map((epc) => {
                                return tagsOriginDestination?.find((t) => t.epc === epc);
                              });

                              return (
                                <ListItemProductArcTag
                                  key={index}
                                  tag={tag}
                                  epcsOriginDestination={epcsOriginDestination}
                                  isCollapsable
                                />
                              );
                            });
                          };

                          return (
                            <>
                              {isWideScreen ? (
                                // En pantallas grandes, mostramos ambos destinos sin depender del tabActive
                                <div key={destinationName} className="ArcActivityView__destination-group">
                                  {renderGroupItems(destinationName)}
                                </div>
                              ) : (
                                // En pantallas pequeñas, mostramos solo el grupo activo
                                tabIsActive &&
                                  <div key={destinationName} className="ArcActivityView__destination-group">
                                    {renderGroupItems(destinationName)}
                                  </div>
                              )}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <EmptyArcTags />
                    )}
                  </List>
                </>
              }

              {showView.confirmation &&
                groupedProducts.map((product, productIndex) => (
                  <div key={productIndex} className="ArcActivityView__confirmation-view">
                    <div>
                      <h4>Destino {product.name}</h4>
                      <span>{product.epcs.length}</span>
                    </div>
                    <div>
                      {product.items.map((tag, index) => {
                        const epcsOriginDestination = tag.epcs.map(epc => {
                          return tagsOriginDestination?.find(t => t.epc === epc);
                        });

                        return (
                          <ListItemProductArcTag
                            key={index}
                            tag={tag}
                            epcsOriginDestination={epcsOriginDestination}
                          />
                        );
                      })}
                    </div>
                  </div>
                ))
              }

              {showView.success && <SuccessViewArc numberOrders={numberOrders} />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ActionButtonsArc
        cleanScrenn={cleanScrenn}
        handleBack={handleBackConfirmation}
        handleSubmit={handleSubmit}
        groupedProducts={groupedProducts}
        showView={showView}
        typeLectun={typeLectun}
      />

    </Container>
  );

  const headerOptions = [
    {
      icon: "",
      name: "type-lectun",
      handler: () => setShowSelectType(true),
      tooltip: "Proceso de lectura",
      ic: isMobile
        ? typeLectun === LECTUN_TYPE.MANUAL ? manualLectunIconWhite : autoLectunIconWhite
        : typeLectun === LECTUN_TYPE.MANUAL ? manualLectunIconBlue : autoLectunIconBlue
    },
  ];

  return (
    <div className="device-detail-container">
      <AdminLayout
        headerTitle={i18n.t("Arco")}
        headerOptions={headerOptions}
        content={content}
        navHidden={false}
      />

      {(loading || isLoading) && <Loader />}

      {showSelectType && (
        <SelectTypeLectun
          selected={typeLectun}
          setSelected={setTypeLectun}
          setShow={setShowSelectType}
          setIsLoading={setIsLoading}
          devicePickerRef={devicePickerRef}
        />
      )}
    </div>
  );
};

export default ArcActivityView;
