import style from "./ToggleDeviceRead.module.scss";
import Toggle from "components/MainApp/atoms/Forms/Toggle";

const ToggleDeviceRead = ({ handleToggleChange, disableToggle, channel }) => {
  return (
    <div className={style["toggle-wrapper"]}>
      <p>
        Lectura <span>{channel ? "habilitada" : "deshabilitada"}</span>
      </p>
      <Toggle
        handleChange={() => handleToggleChange(channel)}
        checked={channel}
        disabled={disableToggle}
        name="channel"
      />
    </div>
  );
};

export default ToggleDeviceRead;
