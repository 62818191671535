import React from "react";
import PropTypes from "prop-types";
import imageFile from "../../../../../assets/images/image-placeholder.png";
import imageCompression from 'browser-image-compression';

import {
  MAX_FILE_SIZE_MB,
  FILE_TYPE_ALLOWED,
  FILE_TYPE_VIDEO
} from "../../../../../constants";

import "./styles.scss";
import Loader from "../../Loader";

export class DragAndDropImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: this.props.files
        ? this.props.files.map(file => this.getUrl(file))
        : [],
      drag: false,
      compressing: false
    };
    this.fileTypeAccepted = this.props.fileTypeAccepted || FILE_TYPE_ALLOWED;
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  dropRef = React.createRef();

  getUrl(file) {
    if (file?.file_name === null) return '';
    if (this.props.error) return;
    if (typeof file === "string" && file.startsWith("http")) return file;
    if (
      typeof file?.file_name === "string" &&
      file?.file_name.startsWith("http")
    )
      return file;
    return URL.createObjectURL(file);
  }

  handleFiles = async (event, newFiles) => {
    let invalidFiles = newFiles.filter(
      file => file.size > 1024 * 1024 * MAX_FILE_SIZE_MB
    );
    let validFiles = newFiles.filter(
      file => file.size <= 1024 * 1024 * MAX_FILE_SIZE_MB
    );
    if (invalidFiles.length > 0) {
      this.setState({
        compressing: true
      });
      let newValidFiles = [];
      await Promise.all(invalidFiles.map(file => this.compressImage(file, newValidFiles, validFiles)));
      validFiles.push(...newValidFiles);
      this.setState({
        compressing: false
      });
      this.setImage(validFiles);
    } else {
      this.setImage(event, newFiles);
    }
  };

  async handleChange(event) {
    const newFiles = Array.from(event.target.files);
    this.handleFiles(event, newFiles);
  }

  setImage(event, newFiles) {
    this.setState(prevState => ({
      files: [...(prevState.files || []), ...newFiles]
    }));
    if (this.props.handleChange) this.props.handleChange(event, newFiles);
  }

  async compressImage(file, newValidFiles, validFiles) {
    const options = {
      maxSizeMB: 1,          // Tamaño máximo en MB
      maxWidthOrHeight: 1024, // Redimensionar si excede este tamaño
      useWebWorker: true,    // Usar Web Workers para mejor rendimiento
    };
    return new Promise(async (resolve, reject) => {
      try {
        const compressedFile = await imageCompression(file, options);
        const blobToFile = new File([compressedFile], compressedFile.name, {
          type: compressedFile.type, // Asegúrate de mantener el tipo MIME
          lastModified: Date.now(),  // Fecha de modificación actual
        });
        newValidFiles.push(blobToFile);
        resolve();
      } catch (error) {
        console.error('Error al comprimir:', error);
        this.setState(({
          compressing: false
        }));
        let errorMsg = `El tamaño del archivo no puede superar ${MAX_FILE_SIZE_MB}MB`;
        if (this.props.handleChange)
          this.props.handleChange(
            {
              target: { name: this.props.name, type: "file", files: validFiles }
            },
            [],
            errorMsg
          );
        reject()
      }
    });
  }

  handleDelete(index) {
    this.setState(prevState => {
      const updatedFiles = prevState.files.filter((file, i) => i !== index);
      if (this.props.handleDelete) this.props.handleDelete(updatedFiles);
      return { files: updatedFiles };
    });
  }

  handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };

  handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };

  handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    const newFiles = Array.from(event.dataTransfer.files);
    this.handleFiles(e, newFiles);
  };

  componentDidMount() {
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
  }

  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.files !== this.props.files) {
      this.setState({ files: this.props.files });
    }
  }

  render() {
    const {
      name,
      isSmall,
      showStar = true,
      msg = "Arrastra las imágenes aquí"
    } = this.props;

    return (
      <div
        className={`drag-and-drop-image-container ${isSmall ? "small" : ""}`}
      >
        <div className="drag-and-drop-image-wrapper" ref={this.dropRef}>
          {this.props.msgInfoFiles && (
            <div className="drag-and-drop-image-info">
              {this.props.msgInfoFiles}
            </div>
          )}
          {this.props.showSelectorFiles && (
            <div className="drag-and-drop-image-input-wrapper">
              <img src={imageFile} alt="product" />

              <div className="drag-and-drop-image-text-wrapper">
                <div className="drag-and-drop-image-text">{msg}</div>
                <div className="drag-and-drop-image-text">o</div>
              </div>

              <label className="custom-file-upload">
                Selecciona aquí
                <input
                  type="file"
                  name={name}
                  accept={this.fileTypeAccepted}
                  onChange={this.handleChange}
                  multiple
                ></input>
              </label>
              <label className="drag-and-drop-image-help">
                (Tamaño máx {MAX_FILE_SIZE_MB}MB)
              </label>
            </div>
          )}

          {!this.state.files?.length && (
            <div className="drag-and-drop-image-input-wrapper-mobile">
              <label className="custom-file-upload" htmlFor="mobile-input-file">
                <div className="custom-file-wrapper">
                  <img src={imageFile} alt="product" />
                </div>
              </label>
              <input
                type="file"
                id="mobile-input-file"
                name={name}
                accept={this.fileTypeAccepted}
                onChange={this.handleChange}
                multiple
              ></input>

              <div className="drag-and-drop-bottom-label">
                <label
                  className="drag-and-drop-bottom-label-add"
                  htmlFor="mobile-input-file"
                >
                  Agregar imagen
                </label>
                <label className="drag-and-drop-image-help">
                  (Tamaño máx {MAX_FILE_SIZE_MB}MB)
                </label>
              </div>
            </div>
          )}
          <div className="thumbnails-wrapper">
            {this.state?.files?.map((file, index) => {
              let isVideo = false;
              let src = "";
              if (typeof file === "string" && file.startsWith("blob:")) {
                src = file;
              } else if (file?.file_name) {
                isVideo = file?.file_name?.match(FILE_TYPE_VIDEO);
                if (file.file_name !== null) {
                  src = file?.file_name;
                }
              } else if (file?.name) {
                isVideo = file?.name?.match(FILE_TYPE_VIDEO);
                if (file.name !== null) {
                  src = URL.createObjectURL(file);
                }
              } else {
                isVideo = file?.file_name?.match(FILE_TYPE_VIDEO);
                if (file !== null) {
                  src = file;
                }
              }

              return (
                <div key={index} className="drag-and-drop-image-input-preview">
                  <div className="image-and-icon-wrapper">
                    {!isVideo && (
                      <span
                        style={{ display: showStar ? "block" : "none" }}
                        title="Marcar como imagen principal"
                        className={`star-icon ${this.props.selectedStarIndex === index
                            ? "star-icon-selected"
                            : ""
                          }`}
                        onClick={() => this.props.onHandleStarClick(index)}
                      >
                        ★
                      </span>
                    )}
                    {isVideo ? (
                      <video width="320" height="240" controls>
                        <source src={src} type="video/mp4" />
                        Tu navegador no soporta el elemento de video.
                      </video>
                    ) : (
                      <img src={src} alt={src} />
                    )}
                  </div>

                  <div
                    className="drag-and-drop-image-delete"
                    onClick={() => this.handleDelete(index)}
                    data-automation-id="delete-image"
                  >
                    <i className="icon-iconos_eliminar drag-and-drop-image-icon"></i>
                    Eliminar
                  </div>
                </div>
              );
            })}
          </div>

          {this.props.error && (
            <div className="drag-and-drop-image-error">{this.props.error}</div>
          )}
        </div>
        {this.state.compressing ? <Loader/> : <></>}
      </div>
    );
  }
}

DragAndDropImages.propTypes = {
  handleChange: PropTypes.func
};

export default DragAndDropImages;
